<template>
  <section class="cluster-table">
    <div class="vx-row">
      <vs-col vs-w="12">
        <h2 class="mb-0">
          {{ lang.clustering.title[languageSelected] }}
          <span v-if="total">({{ total }})</span>
        </h2>
        <vs-button class="float-right" icon="add" @click="createCluster">
          {{ lang.clustering.createCluster.title[languageSelected] }}
        </vs-button>
      </vs-col>
    </div>

    <div class="vx-row">
      <vs-col vs-w="12">
        <div
          class="pt-4 pb-base vs-con-loading__container"
          :id="`div-with-loading-${report._id}`"
        >
          <vs-table
            :data="clusters"
            :max-items="LIMIT"
            pagination
            :total="total"
            ref="table"
            :key="key"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <!-- search
            :sst="true"
            @search="handleSearch"
            @sort="handleSort"
            @change-page="handleChangePage" -->
            <template slot="thead">
              <vs-th>
                {{ lang.clustering.clustersTable.bot[languageSelected] }}
              </vs-th>
              <vs-th sort-key="from">
                {{ lang.clustering.clustersTable.from[languageSelected] }}
              </vs-th>
              <vs-th sort-key="to">
                {{ lang.clustering.clustersTable.to[languageSelected] }}
              </vs-th>
              <vs-th sort-key="status">
                {{ lang.clustering.clustersTable.status[languageSelected] }}
              </vs-th>
              <vs-th>
                {{ lang.clustering.clustersTable.actions[languageSelected] }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :key="indextr"
                v-for="(tr, indextr) in data"
                class="bg-white"
              >
                <vs-td :data="tr.service && tr.service.name">
                  {{ tr.service && tr.service.name }}
                </vs-td>
                <vs-td :data="tr.from">
                  {{ tr.from | moment(dateFormat) }}
                </vs-td>
                <vs-td :data="tr.to">
                  {{ tr.to | moment(dateFormat) }}
                </vs-td>
                <vs-td :data="tr.status">
                  <vs-chip :color="getStatusColor(tr.status)">
                    <vs-avatar
                      color="transparent"
                      :icon="getStatusIcon(tr.status)"
                      icon-pack="feather"
                    />
                    {{ getClusterStatus(tr.status) }}
                  </vs-chip>
                </vs-td>
                <vs-td class="actions">
                  <vs-button
                    :title="
                      lang.clustering.clustersTable.actionsTooltips.goToCenters[
                        languageSelected
                      ]
                    "
                    radius
                    color="primary"
                    type="flat"
                    icon="icon-target"
                    icon-pack="feather"
                    size="small"
                    @click.stop="goToCenters(tr._id)"
                    class="mr-2"
                  />
                  <vs-button
                    :title="
                      lang.clustering.clustersTable.actionsTooltips
                        .downloadQuestions[languageSelected]
                    "
                    radius
                    color="primary"
                    type="flat"
                    icon="icon-download"
                    icon-pack="feather"
                    size="small"
                    @click.stop="downlodaUnknownQuestions(tr.unknownFile)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-col>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store/store'

const STATUS_COLOR = {
  ready: 'success',
  training: 'warning',
  error: 'danger'
}

const STATUS_ICONS = {
  ready: 'icon-check',
  training: 'icon-clock',
  error: 'icon-x'
}

export default {
  name: 'ClusteringTableV1',
  props: {
    report: Object,
    interval: Object,
    services: Array
  },
  components: {
    VsTable: () => import('../../../../../components/vx-table/vsTable'),
    VsTh: () => import('../../../../../components/vx-table/vsTh'),
    VsTr: () => import('../../../../../components/vx-table/vsTr'),
    VsTd: () => import('../../../../../components/vx-table/vsTd')
  },
  data() {
    return {
      LIMIT: 25, // cantidad de elementos por página
      key: new Date().getTime(),

      result: [],
      total: null,
      currentPage: 1,

      orderByColumn: '_lastMessageAt',
      orderByOrder: 'desc',

      search: '',
      searchTimeout: null,
      lastSearch: '',
      searchAwait: 700,

      fullnameLike: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('clusters', ['clusters']),
    // TODO: global
    dateFormat() {
      return this.languageSelected === 'es'
        ? 'DD/MM/YYYY' // , h:mm:ss A'
        : 'YYYY/MM/DD' // , h:mm:ss A'
    }
  },
  methods: {
    ...mapActions('clusters', ['getClusters']),
    reset() {
      this.result = []
      this.total = 0
      this.currentPage = 1

      this.orderByColumn = '_lastMessageAt'
      this.orderByOrder = 'desc'

      this.search = ''
      this.searchTimeout = null
      this.lastSearch = ''
      this.searchAwait = 700

      this.fullnameLike = ''
    },
    handleSort(column, order) {
      if (!order) {
        return
      }
      this.orderByColumn = column
      this.orderByOrder = order
      this.load(this.report, this.LIMIT, 0, this.currentPage !== 1)
    },
    handleChangePage(page) {
      // quitamos los elementos que estaban abiertos, para que no se rompa
      this.$el.querySelectorAll('.tr-expand').forEach(e => {
        if (e.parentNode) {
          e.parentNode.removeChild(e)
        }
      })
      this.currentPage = page
      if (Math.ceil(this.result.length / this.LIMIT) <= page) {
        this.load(this.report, this.LIMIT, this.result.length)
      } else {
        // workaround para hacer que actualize cuando vamos hacia atrás
        const aux = this.result
        this.result = []
        this.$nextTick(() => {
          this.result = aux
          this.currentPage = page
        })
      }
    },
    handleSearch(arg1) {
      this.search = (arg1 || '').trim()
      if (this.search !== this.lastSearch) {
        this.searchTimeout = new Date(Date.now() + this.searchAwait)
      } else {
        this.searchTimeout = null
      }
    },
    getClusterStatus(status) {
      return (
        this.lang.clustering.clustersTable.statusNames[status][
          this.languageSelected
        ] ||
        this.lang.clustering.clustersTable.statusNames['error'][
          this.languageSelected
        ]
      )
    },
    getStatusColor(status) {
      return STATUS_COLOR[status] || STATUS_COLOR['error']
    },
    getStatusIcon(status) {
      return STATUS_ICONS[status] || STATUS_ICONS['error']
    },
    goToCenters(clusterId) {
      this.$router.push(`/clustering/${clusterId}/centers`)
    },
    downlodaUnknownQuestions(unknownFile) {
      const backendUrl = store.state.config.konecta
      const url =  /^http.*/.test(unknownFile) ? unknownFile : backendUrl+unknownFile
      window.open(url, '_self')
    },
    createCluster() {
      this.$emit('onCreate')
    },
    async updateClusters() {
      this.getClusters()
    }
  },
  mounted() {
    this.getClusters()
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.cluster-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
    display: inline-block;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }

  .actions span {
    display: flex;
  }
}
</style>
